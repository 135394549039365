// @import 'bootstrap/variables';

// utils
.can-click {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden-xl {
  @include media-breakpoint-only(xl) {
    display: none;
  }
}

.hidden-lg {
  @include media-breakpoint-only(lg) {
    display: none;
  }
}

.hidden-md {
  @include media-breakpoint-only(md) {
    display: none;
  }
}

.hidden-sm {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hidden-xs {
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.modal-backdrop-light {
  background: map-get($map: $theme-colors, $key: light);
  opacity: 1;

  &.modal-backdrop.show {
    opacity: 1;
  }
}

.thead-sticky {
  position: sticky;
  z-index: 1;
  background: #fff;

  &.groups {
    top: 100px;
  }

  &.tags {
    top: 120px;
  }
}

.thead-sticky-top {
  @extend .thead-sticky;
  top: -4px;
}

.thead-sticky-center {
  @extend .thead-sticky;
  top: 58px;
}

.thead-sticky-total {
  @extend .thead-sticky;
  top: 125px;
  z-index: 0;
}

.thead-sticky-bottom {
  @extend .thead-sticky;
  top: 173px;
  &.lottery {
    top: 50px;
  }
}

.sort-active-th {
  min-width: fit-content;
  cursor: pointer;
}

.sort-active-th .centred {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bold {
  font-weight: 700;
}

button:disabled {
  cursor: not-allowed;
}
