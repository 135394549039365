.avatar-container {
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    opacity: 0;
    cursor: pointer;
  }

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
}

.group-avatar {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.select-wrapper {
  height: 120px;
  overflow: auto;
  cursor: pointer;
  border: 1px solid #00000020;

  .select-option {
    padding: 3px 5px;
  }
}
